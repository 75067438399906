// import React,{useContext, useEffect} from 'react';
// import { View, Text, Button } from 'react-native';
// import CreatePost from '../components/CreatePost';
// import AsyncStorage from '@react-native-async-storage/async-storage';

// import AuthContext from '../auth/context';

// export default function Dashboard({ navigation }) {

// const { logout } = useContext(AuthContext); // Use the logout function from AuthContext

//   return (
//     <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
//       <Text>Home Screen</Text>
//       <Button title="Go to Create Post" onPress={() => navigation.navigate('Post')} />
//       <Button title="Logout" onPress={logout} />
//     </View>
//   );
// }
import React, { useState, useContext, useEffect } from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator, StyleSheet, Image, ScrollView,TouchableOpacityBase, Dimensions } from 'react-native';
import colors from '../config/colors';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthContext from '../auth/context';
import FormattedDateFancy from '../components/FormattedDateFancy'
import { Ionicons, FontAwesome, EvilIcons, FontAwesome6, MaterialCommunityIcons  } from '@expo/vector-icons';
//import { FontAwesome6 } from '@expo/vector-icons';

// import {LineChart, BarChart, PieChart, ProgressChart, ContributionGraph, StackedBarChart } from "react-native-chart-kit";
import ActivityList from '../components/ActivityList'
import dashboard from '../api/dashboard';
import MoneyFormat from '../components/MoneyFormat'

import { PieChart, LineChart } from "react-native-gifted-charts";

const SCREEN_HEIGHT = Dimensions.get('window').height;


const Dashboard = () => {

  useEffect(() => {
    getUserActivities()
    const intervalID = setInterval(getUserActivities, 5000)
    return () => clearInterval(intervalID)
  }, []);

  useEffect(() => {
    getEmpActivities()
    const intervalID = setInterval(getEmpActivities, 5000)
    return () => clearInterval(intervalID)
  }, []);
  
  useEffect(() => {
    fetchDepositMediumSummary()
    const intervalID = setInterval(fetchDepositMediumSummary, 60000)
    return () => clearInterval(intervalID)
  }, []);
  
  useEffect(() => {
    fetchWithdrawalMediumSummary()
    const intervalID = setInterval(fetchWithdrawalMediumSummary, 60000)
    return () => clearInterval(intervalID)
  }, []);

  useEffect(() => {
    fetchDepositPaymentSummary()
    const intervalID = setInterval(fetchDepositPaymentSummary, 60000)
    return () => clearInterval(intervalID)
  }, []);

  useEffect(() => {
    fetchWithdrawalPaymentSummary()
    const intervalID = setInterval(fetchWithdrawalPaymentSummary, 60000)
    return () => clearInterval(intervalID)
  }, []);

  useEffect(() => {
    fetchTotalDeposit()
    const intervalID = setInterval(fetchTotalDeposit, 60000)
    return () => clearInterval(intervalID)
  }, []);

  useEffect(() => {
    fetchTotalWithdrawal()
    const intervalID = setInterval(fetchTotalWithdrawal, 60000)
    return () => clearInterval(intervalID)
  }, []);

  useEffect(() => {
    fetchActiveEqub()
    const intervalID = setInterval(fetchActiveEqub, 60000)
    return () => clearInterval(intervalID)
  }, []);

  useEffect(() => {
    fetchActiveCollectors()
    const intervalID = setInterval(fetchActiveCollectors, 60000)
    return () => clearInterval(intervalID)
  }, []);

  useEffect(() => {
    fetchActiveSavers()
    const intervalID = setInterval(fetchActiveSavers, 60000)
    return () => clearInterval(intervalID)
  }, []);

  const { user, logout, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)
  const UserID = user.userId
  const coTin = user.userCompTin
  
  
  const [empActivities, setEmpActivities] = useState([]);
  const [errorGettingEmpActivities, seterrorGettingEmpActivities] = useState(false)
  
  const [activities, setActivities] = useState([]);
  const [errorGettingActivities, seterrorGettingActivities] = useState(false)

  const getUserActivities = async () => {
    // setgettingActivities(true)
    try {
        const response = await dashboard.getUserActivity(coTin);
        if (response.status === 200) {
            setActivities(response.data);
            seterrorGettingActivities(false);
        } else {
            console.error(`Error: ${response.status}`);
            seterrorGettingActivities(false);
        }
      } 
      catch (error) {
          if(error.response && error.response.status === 401){
            logout()
          }
          else{
            console.error(error);
          }
      }
  };

  const getEmpActivities = async () => {
    // setgettingActivities(true)
    try {
        const response = await dashboard.getEmpActivity(coTin);
        if (response.status === 200) {
          setEmpActivities(response.data);
            seterrorGettingEmpActivities(false);
        } else {
            console.error(`Error: ${response.status}`);
            seterrorGettingEmpActivities(false);
        }
      } catch (error) {
          if(error.response && error.response.status === 401){
            logout()
          }
          else{
            console.error(error);
          }
        }
  };

  const [depositSummary, setDepositSummary] = useState([])
  const [fetchingDepositSummary, setFetchingDepositSummary] = useState(false)

  const fetchDepositMediumSummary = async () => {
    setFetchingDepositSummary(true);
    try {
      const response = await dashboard.getDepositMediumSummary(coTin);
      if (response.status === 200) {
        // Apply colors dynamically to the fetched data
        const dataWithColors = response.data.map((item, index) => ({
          ...item,
          color: index === 0 ? colors.PrimaryBck : index === 1 ? colors.SecondaryBck : colors.DefaultColor,
        }));
  
        setDepositSummary(dataWithColors);
        setFetchingDepositSummary(false);
      } else {
        console.error(`Error: ${response.status}`);
        setFetchingDepositSummary(false);
      }
    } catch (error) {
      setFetchingDepositSummary(false);
      console.error(error);
    }
  };

  const [WithdrawalSummary, setWithdrawalSummary] = useState([])
  const [fetchingWithdrawalSummary, setFetchingWithdrawalSummary] = useState(false)

  const fetchWithdrawalMediumSummary = async () => {
    setFetchingWithdrawalSummary(true);
    try {
      const response = await dashboard.getWithdrawalMediumSummary(coTin);
      if (response.status === 200) {
        // Apply colors dynamically to the fetched data
        const dataWithColors = response.data.map((item, index) => ({
          ...item,
          color: index === 0 ? colors.PrimaryBck : index === 1 ? colors.SecondaryBck : colors.DefaultColor,
        }));
  
        setWithdrawalSummary(dataWithColors);
        setFetchingWithdrawalSummary(false);
      } else {
        console.error(`Error: ${response.status}`);
        setFetchingWithdrawalSummary(false);
      }
    } catch (error) {
      setFetchingWithdrawalSummary(false);
      console.error(error);
    }
  };
  const [depositPerMedium, setDepositPerMedium] = useState([]);
  const [gettingDepoPerMedium, setGettingDepoPerMedium] = useState(false)

  const fetchDepositPaymentSummary = async () => {
    setGettingDepoPerMedium(true)
    try {
        const response = await dashboard.getDepositPerMedium(coTin);
        if (response.status === 200) {
          setDepositPerMedium(response.data);
          setGettingDepoPerMedium(false);
        } else {
            console.error(`Error: ${response.status}`);
            setGettingDepoPerMedium(false);
        }
      } 
      catch (error) {
          if(error.response && error.response.status === 401){
            logout()
          }
          else{
            console.error(error);
          }
      }
  };

  const [WithdrawalPerMedium, setWithdrawalPerMedium] = useState([]);
  const [gettingWithdrawalPerMedium, setGettingWithdrawalPerMedium] = useState(false)

  const fetchWithdrawalPaymentSummary = async () => {
    setGettingWithdrawalPerMedium(true)
    try {
        const response = await dashboard.getWithdrawalPerMedium(coTin);
        if (response.status === 200) {
          setWithdrawalPerMedium(response.data);
          setGettingWithdrawalPerMedium(false);
        } else {
            console.error(`Error: ${response.status}`);
            setGettingWithdrawalPerMedium(false);
        }
      } 
      catch (error) {
          if(error.response && error.response.status === 401){
            logout()
          }
          else{
            console.error(error);
          }
      }
  };
  
  const [totalDeposit, setTotalDeposit] = useState([]);
  const [gettingTotalDeposit, setGettingTotalDeposit] = useState(false)

  const fetchTotalDeposit = async () => {
    setGettingTotalDeposit(true)
    try {
        const response = await dashboard.getTotalDeposit(coTin);
        if (response.status === 200) {
          setTotalDeposit(response.data);
          setGettingTotalDeposit(false);
        } else {
            console.error(`Error: ${response.status}`);
            setGettingTotalDeposit(false);
        }
      } 
      catch (error) {
          if(error.response && error.response.status === 401){
            logout()
          }
          else{
            console.error(error);
          }
      }
  };

  const [totalWithdrawal, setTotalWithdrawal] = useState([]);
  const [gettingTotalwithdrawal, setGettingTotalWithdrawal] = useState(false)

  const fetchTotalWithdrawal = async () => {
    setGettingTotalWithdrawal(true)
    try {
        const response = await dashboard.getTotalWithdrawal(coTin);
        if (response.status === 200) {
          setTotalWithdrawal(response.data);
          setGettingTotalWithdrawal(false);
        } else {
            console.error(`Error: ${response.status}`);
            setGettingTotalWithdrawal(false);
        }
      } 
      catch (error) {
          if(error.response && error.response.status === 401){
            logout()
          }
          else{
            console.error(error);
          }
      }
  };

  const [activeEqub, setActiveEqub] = useState([]);
  const [gettingActiveEqub, setGettingActiveEqub] = useState(false)

  const fetchActiveEqub = async () => {
    setGettingActiveEqub(true)
    try {
        const response = await dashboard.getActiveEqub(coTin);
        if (response.status === 200) {
          setActiveEqub(response.data);
          setGettingActiveEqub(false);
        } else {
            console.error(`Error: ${response.status}`);
            setGettingActiveEqub(false);
        }
      } 
      catch (error) {
          if(error.response && error.response.status === 401){
            logout()
          }
          else{
            console.error(error);
          }
      }
  };

  const [activeCollectors, setActiveCollectors] = useState([]);
  const [gettingActiveCollectors, setGettingActiveCollectors] = useState(false)

  const fetchActiveCollectors = async () => {
    setGettingActiveCollectors(true)
    try {
        const response = await dashboard.getActiveCollectors(coTin);
        if (response.status === 200) {
          setActiveCollectors(response.data);
          setGettingActiveCollectors(false);
        } else {
            console.error(`Error: ${response.status}`);
            setGettingActiveCollectors(false);
        }
      } 
      catch (error) {
          if(error.response && error.response.status === 401){
            logout()
          }
          else{
            console.error(error);
          }
      }
  };

  const [activeSavers, setActiveSavers] = useState([]);
  const [gettingActiveSavers, setGettingActiveSavers] = useState(false)

  const fetchActiveSavers = async () => {
    setGettingActiveSavers(true)
    try {
        const response = await dashboard.getActiveSavers(coTin);
        if (response.status === 200) {
          setActiveSavers(response.data);
          setGettingActiveSavers(false);
        } else {
            console.error(`Error: ${response.status}`);
            setGettingActiveSavers(false);
        }
      } 
      catch (error) {
          if(error.response && error.response.status === 401){
            logout()
          }
          else{
            console.error(error);
          }
      }
  };
    
  const renderDot = color => {
    return (
      <View
        style={{
          height: 12,
          width: 12,
          borderRadius: 10,
          backgroundColor: color,
          marginRight: 10,
        }}
      />
    );
  };

  const renderDepositLegendComponent = () => {
      return (
        <>
          <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: 140,
                marginRight: 20,
              }}>
              {renderDot(colors.PrimaryBck)}
              {depositSummary && depositSummary[0] ? <Text style={{color: colors.PrimaryBck}}>{depositSummary[0].payment_medium.charAt(0).toUpperCase() + depositSummary[0].payment_medium.slice(1)} {depositSummary[0].deposit_percent}%</Text>:''}
            </View>
            
            <View
              style={{flexDirection: 'row', alignItems: 'center', width: 120}}>
              {renderDot(colors.SecondaryBck)}
              {depositSummary && depositSummary[1] ? <Text style={{color: colors.PrimaryBck}}>{depositSummary[1].payment_medium.charAt(0).toUpperCase() + depositSummary[1].payment_medium.slice(1)} {depositSummary[1].deposit_percent}%</Text>:''}
           </View>
          </View>
        </>
      );
    };

  const renderWithdrawalLegendComponent = () => {
      return (
        <>
          <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: 140,
                marginRight: 20,
              }}>
              {renderDot(colors.PrimaryBck)}
              {WithdrawalSummary && WithdrawalSummary[0] ? <Text style={{color: colors.PrimaryBck}}>{WithdrawalSummary[0].payment_medium.charAt(0).toUpperCase() + WithdrawalSummary[0].payment_medium.slice(1)} {WithdrawalSummary[0].deposit_percent}%</Text>:''}
            </View>
            
            <View
              style={{flexDirection: 'row', alignItems: 'center', width: 120}}>
              {renderDot(colors.SecondaryBck)}
              {WithdrawalSummary && WithdrawalSummary[1] ? <Text style={{color: colors.PrimaryBck}}>{WithdrawalSummary[1].payment_medium.charAt(0).toUpperCase() + WithdrawalSummary[1].payment_medium.slice(1)} {WithdrawalSummary[1].deposit_percent}%</Text>:''}
           </View>
          </View>
        </>
      );
    };
 
  const data = [{value: 15, label:'Jan'}, {value: 30, label:'Jan'}, {value: 26, label:'Jan'}, {value: 40, label:'Jan'}, {value: 40, label:'Jan'}];
   
  return (
    <View style={styles.mainContainer}>
        <View style={styles.headerCotnainer}>
            <View style={styles.headerLeft}>
                <Text style={styles.greetingText}>Hello, {user ? user.userName:null} 👋</Text>
                <Text style={styles.greetingSubText}>Happy <FormattedDateFancy dateString={new Date().toISOString()}/></Text>
            </View>
            {/* <TouchableOpacity style={styles.headerRight}>
                <Ionicons name="notifications-outline" size={24} color="black" />
            </TouchableOpacity> */}
        </View>
        <View style={styles.canvaContainer}>
            <View style={styles.rowTile}>
                <View style={styles.numbersTile}>
               {/* <View style={styles.activitiesHeader}> */}
                  <Text style={styles.headerText}>    User Activities</Text>
                {/* </View> */}
                    {/* <View style={styles.topNumbersTile}>
                        <TouchableOpacity style={styles.numTileLong}>
                            <Text style={styles.tileText}>6,833,726.53</Text>
                            <Text style={styles.tileSubText}>Sale MTD</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.numTile}>
                            <Text style={styles.tileText}>6,748,714.74</Text>
                            <Text style={styles.tileSubText}>Expense MTD</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.numTile}>
                            <Text style={styles.tileText}>6,748,714.74</Text>
                            <Text style={styles.tileSubText}>Expense MTD</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.numTile}>
                            <Text style={styles.tileText}>255,033.00</Text>
                            <Text style={styles.tileSubText}>Commission MTD</Text>
                        </TouchableOpacity>
                    </View> */}
                    <View style={styles.topNumbersTile}>
                        <TouchableOpacity style={styles.numTile}>
                          {totalWithdrawal.length>0 &&  totalDeposit.length>0?
                              <>
                                <Text style={styles.tileText}>{totalWithdrawal[0].total_withdrawal_amount  && totalDeposit[0].total_deposit_amount  ? <MoneyFormat amount={(parseFloat(totalWithdrawal[0].total_withdrawal_amount) +parseFloat(totalDeposit[0].total_deposit_amount)).toFixed(2)}/>: '0.00'}</Text>
                                <Text style={styles.tileSubText}>Total Transaction</Text>
                              </>
                              :<View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                            }
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.numTile}>
                          {totalDeposit && totalDeposit.length > 0 ?
                            <>
                              <Text style={styles.tileText}><MoneyFormat amount={totalDeposit[0].total_deposit_amount}/></Text>
                              <Text style={styles.tileSubText}>Total Deposit</Text>
                            </>
                            :<View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                          }
                        </TouchableOpacity>

                        <TouchableOpacity style={styles.numTile}>
                        {totalWithdrawal && totalWithdrawal.length > 0 ?
                            <>
                              <Text style={styles.tileText}><MoneyFormat amount={totalWithdrawal[0].total_withdrawal_amount}/></Text>
                              <Text style={styles.tileSubText}>Total Withdrawal</Text>
                            </>
                            :<View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                          }
                        </TouchableOpacity>
                    </View>
                    <View style={styles.bottomNumbersTile}>
                        <TouchableOpacity style={styles.bottomNumTile}>
                          {activeEqub && activeEqub.length > 0 ?
                            <>
                              <Text style={styles.bottomTileText}>{activeEqub[0].active_equb}</Text>
                              <Text style={styles.bottomTileSubText}>Active Equb</Text>
                            </>
                            :
                            <View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                           }
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.bottomNumTile}>
                          {activeCollectors && activeCollectors.length > 0 ?
                            <>
                              <Text style={styles.bottomTileText}>{activeCollectors[0].active_collectors}</Text>
                              <Text style={styles.bottomTileSubText}>Active Collectors</Text>
                            </>
                            :
                            <View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                          }
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.bottomNumTile}>
                          {activeSavers && activeSavers.length > 0 ?
                              <>
                              <Text style={styles.bottomTileText}>{activeSavers[0].active_savers}</Text>
                              <Text style={styles.bottomTileSubText}>Active Savers</Text>
                              </>
                              :
                              <View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                            }
                        </TouchableOpacity>
                        {/* <TouchableOpacity style={styles.bottomNumTile}>
                            <Text style={styles.bottomTileText}>224</Text>
                            <Text style={styles.bottomTileSubText}>Completed Trips MTD</Text>
                        </TouchableOpacity> */}
                    </View>
                </View>
                <View style={styles.chartTileContainer}>
                  <View style={styles.chartTileHeader}>
                    {/* <Text style={styles.headerText}>Performance Overview</Text> */}
                  </View>

                  <View style={styles.chartTile}>
                    <View style={styles.bottomChartContainer}>

                      <View style={styles.bottomLeft}>
                        {depositPerMedium && depositPerMedium.length > 0 ?
                        <>
                            <Text style={styles.bottomTileText}><MoneyFormat amount={depositPerMedium[0].total_deposit_amount}/></Text>
                            <Text style={styles.bottomTileSubText}>{depositPerMedium[0].payment_medium.charAt(0).toUpperCase() + depositPerMedium[0].payment_medium.slice(1)} Deposit</Text>
                        </>
                        :<View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                        }
                      </View>

                      <View style={styles.bottomLeft}>
                        {depositPerMedium && depositPerMedium.length > 0 ?
                        <>
                            <Text style={styles.bottomTileText}><MoneyFormat amount={depositPerMedium[1].total_deposit_amount}/></Text>
                            <Text style={styles.bottomTileSubText}>{depositPerMedium[1].payment_medium.charAt(0).toUpperCase() + depositPerMedium[1].payment_medium.slice(1)} Deposit</Text>
                          
                        </>
                        :<View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                        }
                      </View>

                      <View style={styles.bottomRight}>
                        {WithdrawalPerMedium && WithdrawalPerMedium.length > 0 ?
                          <>
                          <Text style={styles.bottomTileText}><MoneyFormat amount={WithdrawalPerMedium[0].total_withdrawal_amount}/></Text>
                            <Text style={styles.bottomTileSubText}>{WithdrawalPerMedium[0].payment_medium.charAt(0).toUpperCase() + WithdrawalPerMedium[0].payment_medium.slice(1)} Withdrawal</Text>
                            </>
                          
                          
                          :<View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                          }
                      </View>

                      <View style={styles.bottomRight}>
                        {WithdrawalPerMedium && WithdrawalPerMedium.length > 0 ?
                          <>
                          
                          <Text style={styles.bottomTileText}><MoneyFormat amount={WithdrawalPerMedium[1].total_withdrawal_amount}/></Text>
                            <Text style={styles.bottomTileSubText}>{WithdrawalPerMedium[1].payment_medium.charAt(0).toUpperCase() + WithdrawalPerMedium[1].payment_medium.slice(1)} Withdrawal</Text>
                          
                          </>
                          :<View style={{alignSelf:'center'}}><ActivityIndicator size={'small'} color={colors.SecondaryBck}/></View>
                          }
                      </View>

                    </View>
                    <View style={styles.topChartContainer}>

                      <View style={styles.topLeft}>
                      {depositSummary && depositSummary[0] ?
                        <View style={{ width:'100%', margin: 10, padding: 12, paddingTop:4, borderRadius: 7, }}>
                          <Text style={{color: colors.PrimaryBck, fontSize: 16, fontWeight: 'bold'}}>
                            Deposit Medium
                          </Text>
                          <View style={{padding: 15, alignItems: 'center'}}>
                            <PieChart
                              data={depositSummary}
                              donut
                              showGradient
                              sectionAutoFocus
                                radius={90}
                                innerRadius={50}
                                // innerCircleColor={'#232B5D'}
                                innerCircleColor={colors.ForeGroundWhite}
                                centerLabelComponent={() => {
                                return (
                                  <>
                                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                    {depositSummary && depositSummary[0] ? 
                                    <Text
                                        style={{fontSize: 19, color: colors.PrimaryBck, fontWeight: 'bold'}}>
                                     {depositSummary[0].value}
                                      </Text>
                                      :''}
                                      <Text style={{fontSize: 14, color: colors.PrimaryBck}}>    </Text>
                                  </View>
                                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                    {depositSummary && depositSummary[1] ? 
                                    <Text
                                        style={{fontSize: 19, color: colors.SecondaryBck, fontWeight: 'bold'}}>
                                        {depositSummary[1].value}
                                      </Text>
                                      :''}
                                      {/* <Text style={{fontSize: 14, color: colors.PrimaryBck}}>Excellent</Text> */}
                                  </View>
                                  </>
                                );
                              }}
                            />
                          </View>
                          {renderDepositLegendComponent()}
                        </View>
                        : <ActivityIndicator size={'small'} color={colors.SecondaryBck}/>
                      }
                      </View>

                      <View style={styles.topRight}>
                      {WithdrawalSummary && WithdrawalSummary[0] ?
                        <View style={{ width:'100%', margin: 10, padding: 12, paddingTop:4, borderRadius: 7, }}>
                          <Text style={{color: colors.PrimaryBck, fontSize: 16, fontWeight: 'bold'}}>
                            Withdrawal Medium
                          </Text>
                          <View style={{padding: 15, alignItems: 'center'}}>
                            <PieChart
                              data={WithdrawalSummary}
                              donut
                              showGradient
                              sectionAutoFocus
                                radius={90}
                                innerRadius={50}
                                // innerCircleColor={'#232B5D'}
                                innerCircleColor={colors.ForeGroundWhite}
                                centerLabelComponent={() => {
                                return (
                                  <>
                                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                    {WithdrawalSummary && WithdrawalSummary[0] ? 
                                    <Text
                                        style={{fontSize: 19, color: colors.PrimaryBck, fontWeight: 'bold'}}>
                                     {WithdrawalSummary[0].value}
                                      </Text>
                                      :''}
                                      <Text style={{fontSize: 14, color: colors.PrimaryBck}}>    </Text>
                                  </View>
                                    <View style={{justifyContent: 'center', alignItems: 'center'}}>
                                    {WithdrawalSummary && WithdrawalSummary[1] ? 
                                    <Text
                                        style={{fontSize: 19, color: colors.SecondaryBck, fontWeight: 'bold'}}>
                                        {WithdrawalSummary[1].value}
                                      </Text>
                                      :''}
                                      {/* <Text style={{fontSize: 14, color: colors.PrimaryBck}}>Excellent</Text> */}
                                  </View>
                                  </>
                                );
                              }}
                            />
                          </View>
                          {renderWithdrawalLegendComponent()}
                        </View>
                        : <ActivityIndicator size={'small'} color={colors.SecondaryBck}/>
                      }
                      </View>
                    </View>

                    {/* <BarChart
                      data={data}
                      width={925}
                      height={350}
                      //data={data}
                      //width={screenWidth - 20}
                      //height={455}
                      //chartConfig={chartConfig}
                      //style={styles.chartStyle}
                      fromZero
                      showValuesOnTopOfBars
                      //chartConfig={chartConfig}
                      // chartConfig={{
                      //   backgroundColor: colors.ForeGroundWhite,
                      //   backgroundGradientFrom: "#fb8c00",
                      //   backgroundGradientTo: "#ffa726",
                      //   decimalPlaces: 0, // optional, defaults to 2dp
                      //   color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                      //   labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                      //   style: {
                      //     borderRadius: 5
                      //   },
                      //   propsForDots: {
                      //     r: "3",
                      //     strokeWidth: "2",
                      //     stroke: colors.ForeGroundWhite
                      //   }
                      // }}
                        chartConfig = {{
                        backgroundColor: colors.ForeGroundWhite,
                        backgroundGradientFrom: colors.ForeGroundWhite,
                        backgroundGradientTo: colors.ForeGroundWhite,
                        elevation: 7,
                        decimalPlaces:0,
                        color: (opacity = 1) => `rgba(0, 45, 98 ${opacity})`,
                        labelColor: (opacity = 1) => `rgba(0, 45, 98, ${opacity})`,
                        barPercentage: 1.5,
                        useShadowColorFromDataset: false,
                        propsForBackgroundLines: {
                          strokeDasharray: "2", // No dashed lines,
                          color:colors.primary
                        },
                        propsForLabels: {
                          fontSize: 14, // Set the font size for labels here
                      },
                    }}
                      bezier
                      style={{
                          borderRadius: 7,
                          backgroundColor: colors.ForeGroundWhite,
                          width: '100%',
                          height: '100%',
                      }}
                    /> */}
                  </View>
                </View>
            </View>
            <View style={styles.columnTile}>
              <View style={styles.activitiesTileContainerTop}>
                <View style={styles.activitiesHeader}>
                  <Text style={styles.headerText}>Recent Users Activities</Text>
                </View>
                {errorGettingActivities ? 
                <View style={styles.loadingContainer}>
                  <Text style={styles.loadingText}>Getting activity list, please hold.</Text>
                  <ActivityIndicator size="small" color={colors.primary} />
                  </View>
                    : (
                      <ActivityList activities={activities} />
                  )}
              </View>

              <View style={styles.activitiesTileContainerBottom}>
                <View style={styles.activitiesHeader}>
                  <Text style={styles.headerText}>Recent Staff Activities</Text>
                </View>
                {errorGettingEmpActivities ? 
                <View style={styles.loadingContainer}>
                  <Text style={styles.loadingText}>Getting activity list, please hold.</Text>
                  <ActivityIndicator size="small" color={colors.primary} />
                  </View>
                    : (
                      <ActivityList activities={empActivities} />
                  )}
              </View>
                
            </View>
        </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    //flex:1,
    width:'100%',
    height: SCREEN_HEIGHT,
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  headerCotnainer:{
    elevation:4,
    marginTop:10,
    borderRadius:7,
    marginHorizontal:18,
    flexDirection:'row',
    justifyContent:'space-between',
  },
  greetingText:{
    fontSize:19,
    // fontWeight:'400',
    paddingBottom:3,
    fontFamily:'Roboto-Bold',
    color:colors.PrimaryBck
  },
  headerText:{
    fontSize:16,
    // fontWeight:'400',
    paddingBottom:3,
    fontFamily:'Roboto-Bold',
    color:colors.PrimaryBck
  },
  greetingTextReminderTile:{
    fontSize:22,
    //fontWeight:'700',
    marginBottom:5,
    fontFamily:'Roboto-Bold',
    color:colors.primary
  },
  greetingSubText:{
    fontSize:15,
    fontWeight:'300'
  },
  headerLeft:{
    flexDirection:'column',
  },
  headerRight:{
    alignItems:'center',
    justifyContent:'center',
    paddingRight:20
  },
  canvaContainer:{
    flex:1,
    flexDirection:'row'
  },
  rowTile: {
    flex: 1,
    flexDirection: 'column',
    width:'60%',
    alignItems:'center',
    backgroundColor: colors.white,
  },
  numbersTile: {
    height:'30%',
    width:'100%',
    flexDirection:'column',
    marginTop:24,
    justifyContent:'space-evenly'
    // flex: 1, // Provide flex to fill available space
    // backgroundColor: colors.PrimaryBck, // Temporary background for visibility
  },
  topNumbersTile: {
    //flex: 1, // Provide flex to fill available space
    height:'46%',
    width:'100%',
    flexDirection:'row',
    justifyContent:'space-evenly',
    alignItems:'center',
    backgroundColor: colors.white, // Temporary background for visibility
  },
  bottomNumbersTile: {
    //flex: 1, // Provide flex to fill available space
    height:'46%',
    width:'100%',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-evenly',
    backgroundColor: colors.white,
  },
  bottomNumTile:{
    height:'90%',
    width:'31%',
    borderRadius:7,
    backgroundColor:colors.ForeGroundWhite,
    elevation:10,
    shadowRadius:.5,
    shadowColor:colors.primary,
    alignItems:'flex-start',
    justifyContent:'center',
  },
  numTile:{
    height:'90%',
    width:'31%',
    borderRadius:5,
    backgroundColor:colors.ForeGroundWhite,
    elevation:10,
    shadowRadius:.5,
    shadowColor:colors.primary,
    alignItems:'flex-start',
    justifyContent:'center',
  },
  numTileLong:{
    height:'90%',
    width:'64%',
    borderRadius:5,
    backgroundColor:colors.ForeGroundWhite,
    elevation:10,
    shadowRadius:.5,
    shadowColor:colors.primary,
    alignItems:'flex-start',
    justifyContent:'center',
  },
  tileText:{
    fontSize:30,
    fontWeight:'700',
    paddingBottom:7,
    paddingLeft:'10%',
    // alignSelf:'center',
    // color:colors.primary,
    color:colors.PrimaryBck,
    fontFamily:'Roboto-Regular'
  },
  bottomTileText:{
    fontSize:27,
    fontWeight:'700',
    paddingBottom:7,
    paddingLeft:'10%',
    // alignSelf:'center',
    // color:colors.primary,
    color:colors.PrimaryBck,
    fontFamily:'Roboto-Regular',
    // backgroundColor:'orange',width:'100%'
  },
  tileSubText:{
    fontSize:16,
    fontWeight:'400',
    // paddingBottom:3,
    // alignSelf:'center',
    paddingLeft:'10%',
    // color:colors.RasinBlack,
    color:colors.SecondaryBck,
    fontFamily:'Roboto-Light'
  },
  bottomTileSubText:{
    fontSize:16,
    fontWeight:'400',
    // paddingBottom:3,
    // alignSelf:'center',
    paddingLeft:'10%',
    // color:colors.RasinBlack,
    color:colors.SecondaryBck,
    fontFamily:'Roboto-Light'
  },
  chartTileContainer: {
    flex: 1, // Provide flex to fill available space
    width:'100%',
    // height:'60%',
    // alignItems:'center', 
    // justifyContent:'center',
    // backgroundColor:colors.white, // Temporary background for visibility
  },
  chartTileHeader:{
    flexDirection:'row',
    alignItems:'flex-start',
    marginLeft:15,
    paddingTop:12,
    fontFamily:'Roboto-Regular'
  },
  chartTile:{
    height: '93%',
    width: '99%',
    // backgroundColor: colors.ForeGroundWhite,
    // borderRadius: 7,
    // elevation: 10,
    // shadowRadius: 0.5,
    //shadowColor: colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf:'center',
    flexDirection:'column'
  },
  topChartContainer:{
    height:'70%',
    width:'100%',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-evenly',
    marginTop:10,
  },
  topLeft:{
    height:'95%',
    width:'48%',
    alignItems:'center',
    justifyContent:'center',
    borderRadius: 7,
    elevation: 10,
    shadowRadius: 0.5,
    backgroundColor:colors.ForeGroundWhite,
  },
  topRight:{
    height:'95%',
    width:'48%',
    alignItems:'center',
    justifyContent:'center',
    borderRadius: 7,
    elevation: 10,
    shadowRadius: 0.5,
    margin:10,
    backgroundColor:colors.ForeGroundWhite,

  },
  bottomChartContainer:{
    height:'30%',
    width:'100%',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-evenly',
    // marginTop:10,
  },
  bottomLeft:{
    height:'95%',
    width:'24%',
    alignItems:'flex-start',
    justifyContent:'center',
    flexDirection:'column',
    borderRadius: 7,
    elevation: 10,
    shadowRadius: 0.5,
    backgroundColor:colors.ForeGroundWhite,
  },
  bottomRight:{
    height:'95%',
    width:'24%',
    alignItems:'flex-start',
    justifyContent:'center',
    flexDirection:'column',
    borderRadius: 7,
    elevation: 10,
    shadowRadius: 0.5,
    backgroundColor:colors.ForeGroundWhite,
  },
  columnTile: {
    flex: 0.5, // Use flex instead of height and width
    flexDirection: 'column',
    width:'40%',
    backgroundColor: colors.white,
  },
  activitiesTileContainerTop: {
    //flex: 1, // Provide flex to fill available space
    height:'49.5%',
    backgroundColor: colors.white, // Temporary background for visibility
    alignItems:'center',
    //justifyContent:'center',
    // paddingVertical:5,
    //alignSelf:'flex-start',
    width:'100%',
  },
  activitiesTileContainerBottom: {
    marginTop:5,
    //flex: 1, // Provide flex to fill available space
    height:'49%',
    backgroundColor: colors.white, // Temporary background for visibility
    alignItems:'center',
    //justifyContent:'center',
    // paddingVertical:5,
    //alignSelf:'flex-start',
    width:'100%',
  },
  activitiesHeader:{
    paddingTop:0,
    paddingLeft:5,
    paddingBottom:3,
    width:'100%'
  },
  reminderTileContiner: {
    //flex: 1, // Provide flex to fill available space
    height:'48%',
    alignItems:'flex-start',
    justifyContent:'center',
    paddingTop:7,
    backgroundColor: colors.white, // Temporary background for visibility
  },
  reminderHeader:{
    paddingTop:0,
    paddingLeft:5,
    paddingBottom:3,
  },
  reminderTile:{
    height:'97%',
    width:'97%',
    backgroundColor:colors.ForeGroundWhite,
    // paddginTop:2,
    // paddingRight:2,
    // paddingBottom:2
    elevation:10,
    shadowRadius:.5,
    shadowColor:colors.primary,
    borderRadius:7,
    paddingTop:5,
    paddingBottom:5,
    fontFamily:'Roboto-Regular'
  },
  reminderTileCard:{
    height:40,
    flexDirection:'row',
    backgroundColor:colors.white,
    marginHorizontal:5,
    borderRadius:4,
    marginBottom:4,
  },
  reminderUserIcon:{
    width:'1%',
    width:'12%',
    alignItems:'center',
    justifyContent:'center',
    //backgroundColor:colors.white,
  },
  reminderNameandActionContainer:{
    alignItems:'flex-start',
    justifyContent:'center',
    marginLeft:2
  },
  reminderNameContainer:{
    flexDirection:'row'
  },
  reminderText:{
    fontSize:16,
    fontWeight:'400',
    paddingBottom:3,
    color:colors.primary,
    fontFamily:'Roboto-Medium'
  },
  reminderSubText:{
    fontSize:15,
    fontWeight:'300',
    color:colors.primary,
    fontFamily:'Roboto-Light'
},
loadingContainer:{
  alignItems:'center',
  justifyContent:'center',
  flex:1,
},
  loadingText:{
    fontSize:17,
    fontWeight:'500',
    color:colors.primary,
    paddingBottom:7,
},
});

export default Dashboard;

